import { VStack } from '@chakra-ui/react';
import React from 'react';

export const SignInTemplate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <VStack
      h="100%"
      justifyContent="center"
      left={0}
      position="fixed"
      top={0}
      w="100%"
    >
      {children}
    </VStack>
  );
};
