import type {
  DocumentData,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import type { timerColors } from './timer';

export const FIREBASE_FUNCTIONS_REGION = 'asia-northeast1';

export type WithIdAndRef<T extends DocumentData> = T & {
  id: string;
  ref: DocumentReference<T>;
};

interface DocumentDataBase extends DocumentData {
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface RoomDocumentData extends DocumentDataBase {
  memberIds: string[];
  ownerId: string;
  password?: string;
  timers: {
    [id: string]: {
      color: keyof typeof timerColors;
      elapsedTimeMs: number;
      index: number;
      startedAtMs?: number;
    };
  };
}

export interface GetServerStatusResponseData {
  time: number;
}

export interface JoinRoomRequestData {
  password: string;
  roomId: string;
}

export interface LeaveRoomRequestData {
  roomId: string;
}

export type SendTimerActionRequestData = {
  roomId: string;
} & (
  | {
      roomId: string;
      timerId: string;
      type: 'start' | 'stop';
    }
  | {
      roomId: string;
      type: 'stopAll';
    }
);
