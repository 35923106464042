import { Spinner, Text } from '@chakra-ui/react';
import { signInAnonymously } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../../infrastructures/firebase';
import { SignInTemplate } from '../templates/SignInTemplate';

export const SignInPage: React.FC = () => {
  const [user, isLoading, error] = useAuthState(firebaseAuth);

  useEffect(() => {
    if (user) return;

    void (async () => {
      const { user } = await signInAnonymously(firebaseAuth);
      console.info('Firebase Auth user', user);
    })();
  }, [user]);

  if (isLoading) {
    return (
      <SignInTemplate>
        <Spinner size="lg" />
      </SignInTemplate>
    );
  }

  if (error) {
    return (
      <SignInTemplate>
        <Text color="gray.500" fontSize="sm">
          不明なエラーが発生しました。しばらくしてからご利用ください。
        </Text>
      </SignInTemplate>
    );
  }

  return (
    <SignInTemplate>
      <Spinner size="lg" />
    </SignInTemplate>
  );
};
