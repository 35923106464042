import { AUTHOR_NAME } from '@board-game-timer/shared/src/app';
import { Box, Container, HStack, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { HiOutlineArrowTopRightOnSquare, HiOutlineUser } from 'react-icons/hi2';
import { firebaseAuth } from '../../infrastructures/firebase';

export const DefaultFooter: React.FC = () => {
  const [user] = useAuthState(firebaseAuth);

  return (
    <Container pb={16} pt={8}>
      {user && (
        <Box
          borderColor="gray.200"
          borderRadius="lg"
          borderWidth="thin"
          mb={8}
          p={4}
        >
          <HStack color="gray.500" fontSize="sm" spacing={2}>
            <HiOutlineUser />
            <Text as="span" userSelect="none">
              仮ID
            </Text>
            <Text as="span" fontFamily="mono" fontWeight="bold">
              {user.uid}
            </Text>
          </HStack>
        </Box>
      )}
      <HStack color="gray.500" fontSize="sm" spacing={8}>
        <Text as="span">&copy; {AUTHOR_NAME}</Text>
        <HStack spacing={4}>
          <Link color="inherit" href="https://remin.jp" isExternal>
            remin.jp <HiOutlineArrowTopRightOnSquare />
          </Link>
          {/* <Link
            color="inherit"
            href="https://github.com/reminjp/board-game-timer"
            isExternal
          >
            GitHub <HiOutlineArrowTopRightOnSquare />
          </Link> */}
        </HStack>
      </HStack>
    </Container>
  );
};
