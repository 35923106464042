import type { RoomDocumentData } from '@board-game-timer/shared/src/firebase';
import { timerColors } from '@board-game-timer/shared/src/timer';
import {
  Button,
  Grid,
  Skeleton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { HiOutlineMinus, HiOutlinePause, HiOutlinePlay } from 'react-icons/hi2';
import { useHarmonicIntervalFn } from 'react-use';
import { useRoomSelector } from '../../contexts/room';
import { sendTimerAction, serverTime } from '../../infrastructures/firebase';

interface PureProps {
  color: RoomDocumentData['timers'][string]['color'];
  elapsedTimeMs: number;
  onClick(): void;
  startedAtMs?: number;
}

const PureTimerButton: React.FC<PureProps> = ({
  color,
  elapsedTimeMs,
  onClick,
  startedAtMs,
}) => {
  const [value, setValue] = useState(elapsedTimeMs);
  const [isLoading, setIsLoading] = useState(false);

  useHarmonicIntervalFn(
    () => {
      if (!startedAtMs) return;
      setValue(elapsedTimeMs + (serverTime() - startedAtMs));
    },
    startedAtMs ? 50 : null
  );

  const _onClick = useCallback(() => {
    onClick();
    setIsLoading(true);
  }, [onClick]);

  useEffect(() => {
    setIsLoading(false);

    if (startedAtMs) return;
    setValue(elapsedTimeMs);
  }, [elapsedTimeMs, startedAtMs]);

  const m = Math.floor(value / 1000 / 60);
  const s = Math.floor((value / 1000) % 60);
  const ds = Math.floor((value % 1000) / 100);

  return (
    <Button
      borderColor={color === 'white' ? 'blackAlpha.200' : undefined}
      borderRadius="xl"
      borderWidth={color === 'white' ? 'thin' : undefined}
      boxShadow="sm"
      color={timerColors[color].labelColor}
      colorScheme={timerColors[color].colorScheme}
      h="auto"
      onClick={_onClick}
      p={0}
      sx={{ aspectRatio: '1 / 1' }}
      w="100%"
    >
      <VStack spacing={4}>
        <VStack spacing={2}>
          {isLoading ? (
            <HiOutlineMinus />
          ) : startedAtMs ? (
            <HiOutlinePause />
          ) : (
            <HiOutlinePlay />
          )}
          <Text as="div" fontFamily="mono">
            <Text as="span" fontSize="xl">
              {m ? `${m}:${s.toString().padStart(2, '0')}` : s}
            </Text>
            <Text as="span" fontSize="sm">
              .{ds}
            </Text>
          </Text>
        </VStack>
        <Text as="div" fontSize="xs">
          {timerColors[color].name}
        </Text>
      </VStack>
    </Button>
  );
};

interface Props {
  timerId: string;
}

const _TimerButton: React.FC<Props> = ({ timerId }) => {
  const roomId = useRoomSelector((v) => v.data.id);

  const color = useRoomSelector((v) => v.data.timers[timerId].color);
  const elapsedTimeMs = useRoomSelector(
    (v) => v.data.timers[timerId].elapsedTimeMs
  );
  const startedAtMs = useRoomSelector(
    (v) => v.data.timers[timerId].startedAtMs
  );

  const toast = useToast();

  const onClick = useCallback<() => void>(async () => {
    try {
      await sendTimerAction({
        roomId,
        timerId,
        type: startedAtMs ? 'stop' : 'start',
      });
    } catch (error) {
      console.error(error);
      toast({
        description:
          'タイマーの操作に失敗しました。しばらくしてから再度お試しください。',
        duration: null,
        isClosable: true,
        status: 'error',
      });
    }
  }, [roomId, startedAtMs, timerId, toast]);

  return (
    <PureTimerButton
      color={color}
      elapsedTimeMs={elapsedTimeMs}
      key={timerId}
      onClick={onClick}
      startedAtMs={startedAtMs}
    />
  );
};

export const TimerButton = React.memo(_TimerButton);

export const TimerButtonSkeleton: React.FC = () => {
  return <Skeleton h="auto" sx={{ aspectRatio: '1 / 1' }} />;
};

export const TimerButtonGrid: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Grid
      gap={4}
      templateColumns="repeat(auto-fill, minmax(min(128px, 100%), 1fr))"
    >
      {children}
    </Grid>
  );
};
