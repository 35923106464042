import { Container, IconButton, Skeleton } from '@chakra-ui/react';
import React from 'react';
import {
  HiOutlineCog8Tooth,
  HiOutlineHome,
  HiOutlineShare,
} from 'react-icons/hi2';
import { Link as RouterLink } from 'react-router-dom';
import { Header } from '../molecules/Header';
import { DefaultFooter } from '../organisms/DefaultFooter';

interface Props {
  children: React.ReactNode;
  isOwner?: boolean;
  onSettingsClick?(): void;
  onShareClick?(): void;
  roomName?: string;
}

const _RoomTemplate: React.FC<Props> = ({
  children,
  isOwner,
  onSettingsClick,
  onShareClick,
  roomName,
}) => {
  return (
    <>
      <Header
        endActions={
          isOwner ? (
            <>
              <IconButton
                aria-label="share"
                isDisabled={!onShareClick}
                onClick={onShareClick}
                variant="ghost"
              >
                <HiOutlineShare />
              </IconButton>
              <IconButton
                aria-label="settings"
                isDisabled={!onSettingsClick}
                onClick={onSettingsClick}
                variant="ghost"
              >
                <HiOutlineCog8Tooth />
              </IconButton>
            </>
          ) : undefined
        }
        startActions={
          <IconButton aria-label="home" as={RouterLink} to="/" variant="ghost">
            <HiOutlineHome />
          </IconButton>
        }
      >
        {roomName ?? <Skeleton h="1em" w="8em" />}
      </Header>
      <Container pt={4}>{children}</Container>
      <DefaultFooter />
    </>
  );
};

export const RoomTemplate = React.memo(_RoomTemplate);
