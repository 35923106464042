import { ROOM_LIFETIME_MS } from '@board-game-timer/shared/src/app';
import type {
  RoomDocumentData,
  WithIdAndRef,
} from '@board-game-timer/shared/src/firebase';
import {
  Box,
  Button,
  IconButton,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import React, { useCallback, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  HiOutlineArrowRightOnRectangle,
  HiOutlinePlus,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  createRoom,
  deleteRoom,
  firebaseAuth,
  leaveRoom,
  roomsRef,
} from '../../infrastructures/firebase';
import { formatDateDistance } from '../../utils/date';
import { CardListItem } from '../molecules/CardListItem';
import { HomeTemplate } from '../templates/HomeTemplate';

interface RoomListItemProps {
  data: WithIdAndRef<RoomDocumentData>;
  divider?: boolean;
  userId: string;
}

const RoomListItem: React.FC<RoomListItemProps> = ({
  data,
  divider,
  userId,
}) => {
  const onDeleteClick = useCallback(() => {
    void deleteRoom(data.id);
  }, [data.id]);

  const onLeaveClick = useCallback(() => {
    void leaveRoom({ roomId: data.id });
  }, [data.id]);

  return (
    <LinkBox>
      <CardListItem
        divider={divider}
        endActions={
          data.ownerId === userId ? (
            <IconButton
              aria-label="delete"
              colorScheme="red"
              onClick={onDeleteClick}
              variant="ghost"
            >
              <HiOutlineTrash />
            </IconButton>
          ) : (
            <IconButton
              aria-label="leave"
              onClick={onLeaveClick}
              variant="ghost"
            >
              <HiOutlineArrowRightOnRectangle />
            </IconButton>
          )
        }
        heading={
          <LinkOverlay as={RouterLink} to={`/${data.id}`}>
            {`部屋${data.id.substring(0, 6)}`}
          </LinkOverlay>
        }
        text={
          <>
            {formatDateDistance(data.createdAt?.toMillis() ?? Date.now())} 作成
            &middot;{' '}
            {formatDateDistance(
              (data.createdAt?.toMillis() ?? Date.now()) + ROOM_LIFETIME_MS
            )}
            以降 自動削除
          </>
        }
      />
    </LinkBox>
  );
};

export const HomePage: React.FC = () => {
  const [user] = useAuthState(firebaseAuth);
  if (!user) throw new Error('Unauthorized');

  const navigate = useNavigate();

  const [rooms, isRoomsLoading, roomsError] = useCollectionData(
    query(roomsRef(), where('memberIds', 'array-contains', user.uid))
  );

  const ownedRoomsCount = useMemo(
    () => rooms?.filter((v) => v.ownerId === user.uid).length ?? 0,
    [rooms, user.uid]
  );

  const onCreateClick = useCallback<React.MouseEventHandler>(async () => {
    const roomRef = await createRoom({
      memberIds: [user.uid],
      ownerId: user.uid,
      timers: {
        // for development
        sample0: {
          color: 'red',
          elapsedTimeMs: 0,
          index: 0,
        },
        sample1: {
          color: 'yellow',
          elapsedTimeMs: 0,
          index: 1,
        },
        sample2: {
          color: 'green',
          elapsedTimeMs: 0,
          index: 2,
        },
        sample3: {
          color: 'blue',
          elapsedTimeMs: 0,
          index: 3,
        },
      },
    });
    navigate(`/${roomRef.id}`);
  }, [navigate, user.uid]);

  return (
    <HomeTemplate>
      {isRoomsLoading ? (
        <Skeleton h="2em" />
      ) : roomsError ? (
        <Text as="small" color="gray.600">
          部屋の取得に失敗しました。しばらくしてからご利用ください。
        </Text>
      ) : (
        <>
          {(rooms?.length ?? 0) > 0 && (
            <Box bgColor="white" borderRadius="lg" boxShadow="sm" mb={3}>
              {rooms?.map((v, i, a) => (
                <RoomListItem
                  data={v}
                  divider={i < a.length - 1}
                  key={v.id}
                  userId={user.uid}
                />
              ))}
            </Box>
          )}
          <Button
            isDisabled={ownedRoomsCount >= 10}
            leftIcon={<HiOutlinePlus />}
            onClick={onCreateClick}
            variant="outline"
            w="100%"
          >
            新しい部屋を作成
          </Button>
        </>
      )}
    </HomeTemplate>
  );
};
