import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { deleteField } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useMemo } from 'react';
import { HiOutlineLink } from 'react-icons/hi2';
import { useRoomSelector } from '../../contexts/room';
import { updateRoom } from '../../infrastructures/firebase';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export const RoomShareModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const memberIds = useRoomSelector((v) => v.data.memberIds);
  const ownerId = useRoomSelector((v) => v.data.ownerId);
  const password = useRoomSelector((v) => v.data.password);
  const roomId = useRoomSelector((v) => v.data.id);

  const toggleShareUrl = useCallback(() => {
    void updateRoom(roomId, {
      password: password ? deleteField() : crypto.randomUUID(),
    });
  }, [password, roomId]);

  const shareUrl = useMemo(() => {
    if (!password) return;
    const baseUrl = window.location.href;
    const params = new URLSearchParams({ p: password });
    return `${baseUrl}?${params.toString()}`;
  }, [password]);

  const onCopyClick = useCallback(() => {
    if (!shareUrl) return;
    void window.navigator.clipboard.writeText(shareUrl);
  }, [shareUrl]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>部屋の共有</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl alignItems="center" display="flex" mb={2}>
            <FormLabel
              cursor="pointer"
              flexGrow={1}
              flexShrink={1}
              fontWeight="bold"
              htmlFor="isShareUrlEnabled"
              mb={0}
            >
              共有リンク
            </FormLabel>
            <Switch
              cursor="pointer"
              id="isShareUrlEnabled"
              isChecked={!!shareUrl}
              onChange={toggleShareUrl}
            />
          </FormControl>
          <VStack
            borderColor="gray.200"
            borderRadius="lg"
            borderWidth="thin"
            mb={8}
            p={4}
            spacing={4}
          >
            {shareUrl ? (
              <Box
                as={QRCodeSVG}
                borderRadius="sm"
                size={192}
                value={shareUrl}
              />
            ) : (
              <Box
                bgColor="gray.100"
                borderRadius="sm"
                cursor="not-allowed"
                h="192px"
                w="192px"
              />
            )}
            <Button
              isDisabled={!shareUrl}
              leftIcon={<HiOutlineLink />}
              onClick={onCopyClick}
              variant="outline"
            >
              リンクをコピー
            </Button>
          </VStack>
          <Heading mb={2} size="sm">
            アクセスできるユーザ
          </Heading>
          {memberIds.map((v) => (
            <Box key={v}>
              <Text as="span" fontFamily="mono">
                {v}
              </Text>
              {v === ownerId && (
                <Badge colorScheme="brand" ms={1}>
                  Owner
                </Badge>
              )}
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" onClick={onClose}>
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
