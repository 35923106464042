import { Button } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { HiOutlinePause } from 'react-icons/hi2';
import { useRoomSelector } from '../../contexts/room';
import { sendTimerAction } from '../../infrastructures/firebase';

interface PureProps {
  onClick?(): void;
}

export const PureStopAllButton: React.FC<PureProps> = ({ onClick }) => {
  return (
    <Button
      isDisabled={!onClick}
      leftIcon={<HiOutlinePause />}
      onClick={onClick}
      variant="outline"
      w="100%"
    >
      全て停止
    </Button>
  );
};

const _StopAllButton: React.FC = () => {
  const roomId = useRoomSelector((v) => v.data.id);

  const stopAll = useCallback(() => {
    void sendTimerAction({ roomId, type: 'stopAll' });
  }, [roomId]);

  return <PureStopAllButton onClick={stopAll} />;
};

export const StopAllButton = React.memo(_StopAllButton);
