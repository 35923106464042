import { APP_NAME } from '@board-game-timer/shared/src/app';
import { Container } from '@chakra-ui/react';
import React from 'react';
import { Header } from '../molecules/Header';
import { DefaultFooter } from '../organisms/DefaultFooter';

export const HomeTemplate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <Header>{APP_NAME}</Header>
      <Container pt={4}>{children}</Container>
      <DefaultFooter />
    </>
  );
};
