import type { RoomDocumentData } from '@board-game-timer/shared/src/firebase';
import { timerColors } from '@board-game-timer/shared/src/timer';
import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { deleteField } from 'firebase/firestore';
import type { UpdateData } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import {
  HiEllipsisHorizontal,
  HiOutlineArrowUturnLeft,
  HiOutlinePlus,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { useRoomSelector } from '../../contexts/room';
import { updateRoom } from '../../infrastructures/firebase';

interface TimerListItemProps {
  isDragging: boolean;
  timerId: string;
}

const TimerListItem: React.FC<TimerListItemProps> = ({
  isDragging,
  timerId,
}) => {
  const roomId = useRoomSelector((v) => v.data.id);
  const timers = useRoomSelector((v) => v.data.timers);
  const timerColor = useRoomSelector(
    (v) => timerColors[v.data.timers[timerId].color]
  );
  const timerElapsedTimeMs = useRoomSelector(
    (v) => v.data.timers[timerId].elapsedTimeMs
  );

  const m = Math.floor(timerElapsedTimeMs / 1000 / 60);
  const s = Math.floor((timerElapsedTimeMs / 1000) % 60);
  const ds = Math.floor((timerElapsedTimeMs % 1000) / 100);

  return (
    <Box
      bgColor="white"
      borderColor="gray.200"
      borderRadius="lg"
      borderStyle="solid"
      borderWidth="thin"
      boxShadow={isDragging ? 'lg' : undefined}
      mb={2}
      transitionDuration="normal"
      transitionProperty="box-shadow"
    >
      <HStack px={3} py={1} spacing={3}>
        <Menu gutter={4}>
          <MenuButton
            aria-label="edit color"
            as={IconButton}
            borderRadius="full"
            color={timerColor.labelColor}
            colorScheme={timerColor.colorScheme}
            size="sm"
          >
            <HiEllipsisHorizontal />
          </MenuButton>
          <MenuList maxH="192px" overflowY="scroll">
            {Object.entries(timerColors).map(([k, v]) => (
              <MenuItem
                key={k}
                onClick={() => {
                  void updateRoom(roomId, { [`timers.${timerId}.color`]: k });
                }}
              >
                <Box
                  bgColor={`${v.colorScheme}.500`}
                  borderRadius="full"
                  h="1em"
                  mr={2}
                  w="1em"
                />
                {v.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        <Box flexGrow={1} flexShrink={1}>
          <Heading size="sm" textTransform="capitalize">
            {timerColor.name}
          </Heading>
        </Box>

        <Text as="div" color="gray.400" fontFamily="mono">
          <Text as="span">
            {m ? `${m}:${s.toString().padStart(2, '0')}` : s}
          </Text>
          <Text as="span" fontSize="xs">
            .{ds}
          </Text>
        </Text>

        <HStack spacing={0}>
          <IconButton
            aria-label="reset"
            onClick={() => {
              void updateRoom(roomId, {
                [`timers.${timerId}.elapsedTimeMs`]: 0,
                [`timers.${timerId}.startedAtMs`]: deleteField(),
              });
            }}
            variant="ghost"
          >
            <HiOutlineArrowUturnLeft />
          </IconButton>
          <IconButton
            aria-label="delete"
            colorScheme="red"
            onClick={() => {
              const data: UpdateData<RoomDocumentData> = {
                [`timers.${timerId}`]: deleteField(),
              };
              for (const [k, v] of Object.entries(timers)) {
                if (v.index > timers[timerId].index) {
                  data[`timers.${k}.index`] = v.index - 1;
                }
              }
              void updateRoom(roomId, data);
            }}
            variant="ghost"
          >
            <HiOutlineTrash />
          </IconButton>
        </HStack>
      </HStack>
    </Box>
  );
};

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export const RoomSettingsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const roomId = useRoomSelector((v) => v.data.id);
  const timers = useRoomSelector((v) => v.data.timers);

  const [draftOrders, setDraftOrders] = useState<Record<string, number>>();
  useEffect(() => {
    setDraftOrders(undefined);
  }, [timers]);

  const sortedTimerEntries = useMemo(
    () =>
      Object.entries(timers).sort((a, b) =>
        draftOrders
          ? draftOrders[a[0]] - draftOrders[b[0]]
          : a[1].index - b[1].index
      ),
    [draftOrders, timers]
  );

  const onDragStart = useCallback(() => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const newDraftOrders: Record<string, number> = {};
      const roomUpdateData: UpdateData<RoomDocumentData> = {};

      for (const [timerId, timer] of sortedTimerEntries) {
        let newOrder = timer.index;

        if (result.source.index === timer.index) {
          newOrder = result.destination.index;
        } else {
          if (result.source.index < newOrder) newOrder--;
          if (result.destination.index <= newOrder) newOrder++;
        }

        newDraftOrders[timerId] = newOrder;

        if (newOrder !== timer.index) {
          roomUpdateData[`timers.${timerId}.index`] = newOrder;
        }
      }

      setDraftOrders(newDraftOrders);
      void updateRoom(roomId, roomUpdateData);
    },
    [roomId, sortedTimerEntries]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>部屋の設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading mb={4} size="sm">
            タイマー
          </Heading>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="timers">
              {(provided: DroppableProvided) => (
                <VStack
                  {...provided.droppableProps}
                  alignItems="stretch"
                  ref={provided.innerRef}
                  spacing={0}
                >
                  {sortedTimerEntries.map(([timerId], i) => {
                    return (
                      <Draggable draggableId={timerId} index={i} key={timerId}>
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TimerListItem
                              isDragging={snapshot.isDragging}
                              timerId={timerId}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            isDisabled={Object.entries(timers).length >= 10}
            leftIcon={<HiOutlinePlus />}
            mt={2}
            onClick={() => {
              void updateRoom(roomId, {
                [`timers.${crypto.randomUUID()}`]: {
                  color: 'red',
                  elapsedTimeMs: 0,
                  index: Object.entries(timers).length,
                },
              });
            }}
            variant="outline"
          >
            追加
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" onClick={onClose}>
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
