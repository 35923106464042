import { FIREBASE_FUNCTIONS_REGION } from '@board-game-timer/shared/src/firebase';
import type {
  GetServerStatusResponseData,
  JoinRoomRequestData,
  LeaveRoomRequestData,
  RoomDocumentData,
  SendTimerActionRequestData,
  WithIdAndRef,
} from '@board-game-timer/shared/src/firebase';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import type {
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  UpdateData,
  WithFieldValue,
} from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';

export const firebaseApp = initializeApp();
export const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, FIREBASE_FUNCTIONS_REGION);
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099', {
    disableWarnings: true,
  });
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const roomConverter: FirestoreDataConverter<WithIdAndRef<RoomDocumentData>> = {
  toFirestore(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id, ref, ...data }: PartialWithFieldValue<WithIdAndRef<RoomDocumentData>>
  ): PartialWithFieldValue<RoomDocumentData> {
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<RoomDocumentData>,
    options: SnapshotOptions
  ): WithIdAndRef<RoomDocumentData> {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
      ref: snapshot.ref,
    };
  },
};

export function roomsRef() {
  return collection(firestore, 'rooms').withConverter(roomConverter);
}

export function roomRef(id: string) {
  return doc(firestore, 'rooms', id).withConverter(roomConverter);
}

let _serverTimeOffset = 0;

export function serverTime(): number {
  return Date.now() + _serverTimeOffset;
}

export async function getServerStatus(): Promise<void> {
  const callable = httpsCallable<void, GetServerStatusResponseData>(
    functions,
    'getServerStatus'
  );
  const response = await callable();
  _serverTimeOffset = response.data.time - Date.now();
}

export async function createRoom(data: WithFieldValue<RoomDocumentData>) {
  return await addDoc(roomsRef(), {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    // These fields are removed by the converter.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: undefined as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: undefined as any,
  });
}

export async function updateRoom(
  id: string,
  data: UpdateData<RoomDocumentData>
) {
  return await updateDoc(roomRef(id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

export async function deleteRoom(id: string) {
  return await deleteDoc(roomRef(id));
}

export async function joinRoom(data: JoinRoomRequestData): Promise<void> {
  const callable = httpsCallable<JoinRoomRequestData, void>(
    functions,
    'joinRoom'
  );
  await callable(data);
}

export async function leaveRoom(data: LeaveRoomRequestData): Promise<void> {
  const callable = httpsCallable<LeaveRoomRequestData, void>(
    functions,
    'leaveRoom'
  );
  await callable(data);
}

export async function sendTimerAction(
  data: SendTimerActionRequestData
): Promise<void> {
  const callable = httpsCallable<SendTimerActionRequestData>(
    functions,
    'sendTimerAction'
  );
  await callable(data);
}
