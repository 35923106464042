import NoSleep from 'nosleep.js';
import { useEffect } from 'react';

const noSleep = new NoSleep();

export function useNoSleep() {
  useEffect(() => {
    const enableNoSleep = () => {
      document.removeEventListener('click', enableNoSleep, false);
      void noSleep.enable();
    };

    document.addEventListener('click', enableNoSleep, false);

    return () => {
      document.removeEventListener('click', enableNoSleep, false);
      void noSleep.disable();
    };
  }, []);
}
