import { extendTheme } from '@chakra-ui/react';

const sansSerif = [
  '"Helvetica Neue"',
  'Arial',
  '"Hiragino Kaku Gothic ProN"',
  '"Hiragino Sans"',
  'Meiryo',
  'sans-serif',
].join();

const monospace = [
  'SFMono-Regular',
  'Menlo',
  'Monaco',
  'Consolas',
  '"Hiragino Kaku Gothic ProN"',
  '"Hiragino Sans"',
  'Meiryo',
  'monospace',
].join();

export const theme = extendTheme({
  colors: {
    gray: {
      50: '#fafaf9',
      100: '#f5f5f4',
      200: '#e7e5e4',
      300: '#d6d3d1',
      400: '#a8a29e',
      500: '#78716c',
      600: '#57534e',
      700: '#44403c',
      800: '#292524',
      900: '#1c1917',
    },
    brand: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
    },
    timerRed: {
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
    },
    timerOrange: {
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
    },
    timerYellow: {
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
    },
    timerGreen: {
      500: '#16a34a',
      600: '#15803d',
      700: '#166534',
    },
    timerTeal: {
      500: '#0d9488',
      600: '#0f766e',
      700: '#115e59',
    },
    timerCyan: {
      500: '#22d3ee',
      600: '#06b6d4',
      700: '#0891b2',
    },
    timerBlue: {
      500: '#2563eb',
      600: '#1d4ed8',
      700: '#1e40af',
    },
    timerPurple: {
      500: '#9333ea',
      600: '#7e22ce',
      700: '#6b21a8',
    },
    timerPink: {
      500: '#ec4899',
      600: '#db2777',
      700: '#be185d',
    },
    timerWhite: {
      500: '#fafaf9',
      600: '#f5f5f4',
      700: '#e7e5e4',
    },
    timerGray: {
      500: '#d6d3d1',
      600: '#a8a29e',
      700: '#78716c',
    },
    timerBlack: {
      500: '#44403c',
      600: '#292524',
      700: '#1c1917',
    },
  },
  fonts: {
    body: sansSerif,
    heading: sansSerif,
    mono: monospace,
  },
  components: {
    Badge: {
      baseStyle: {
        textTransform: 'none',
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg',
      },
    },
    Container: {
      baseStyle: {
        maxW: 'container.md',
      },
    },
    Heading: {
      defaultProps: {
        size: 'md',
      },
    },
    Link: {
      baseStyle: {
        color: 'brand.600',
      },
      defaultProps: {
        undefined: '',
      },
    },
  },
  styles: {
    global: {
      body: {
        bgColor: 'gray.100',
        lineHeight: 1.75,
        touchAction: 'manipulation',
      },
    },
  },
});
