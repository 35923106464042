import { Badge, Box } from '@chakra-ui/react';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UiProvider } from './components/organisms/UiProvider';
import { HomePage } from './components/pages/HomePage';
import { RoomPage } from './components/pages/RoomPage';
import { SignInPage } from './components/pages/SignInPage';
import { firebaseApp, firebaseAuth } from './infrastructures/firebase';

const Pages: React.FC = () => {
  const [user] = useAuthState(firebaseAuth);

  if (!user) return <SignInPage />;

  return (
    <Routes>
      <Route element={<HomePage />} path="/" />
      <Route element={<RoomPage />} path="/:roomId" />
    </Routes>
  );
};

export const App: React.FC = () => {
  return (
    <UiProvider>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
      {firebaseApp.options.projectId !== 'board-game-timer-production' && (
        <Box
          color="blackAlpha.500"
          fontSize="sm"
          left={0}
          lineHeight={1}
          p={2}
          pointerEvents="none"
          position="fixed"
          top={0}
        >
          {firebaseApp.options.projectId}
        </Box>
      )}
    </UiProvider>
  );
};
