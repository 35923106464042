import { Container, Heading, HStack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
  endActions?: React.ReactNode;
  startActions?: React.ReactNode;
}

export const Header: React.FC<Props> = ({
  children,
  endActions,
  startActions,
}) => {
  return (
    <Container>
      <HStack h={16} spacing={2}>
        {startActions && (
          <HStack h={16} spacing={1}>
            {startActions}
          </HStack>
        )}
        <Heading flexGrow={1} flexShrink={1}>
          {children}
        </Heading>
        {endActions && (
          <HStack h={16} spacing={1}>
            {endActions}
          </HStack>
        )}
      </HStack>
    </Container>
  );
};
