import { Box, Text } from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce, useInterval } from 'react-use';
import { RoomProvider, useRoomSelector } from '../../contexts/room';
import { useNoSleep } from '../../hooks/useNoSleep';
import {
  firebaseAuth,
  getServerStatus,
  joinRoom,
  roomRef,
} from '../../infrastructures/firebase';
import { PureStopAllButton, StopAllButton } from '../molecules/StopAllButton';
import {
  TimerButton,
  TimerButtonGrid,
  TimerButtonSkeleton,
} from '../molecules/TimerButton';
import { RoomSettingsModal } from '../organisms/RoomSettingsModal';
import { RoomShareModal } from '../organisms/RoomShareModal';
import { RoomTemplate } from '../templates/RoomTemplate';

const LoadingState: React.FC = () => {
  return (
    <RoomTemplate>
      <Box mb={6}>
        <PureStopAllButton />
      </Box>
      <TimerButtonGrid>
        <TimerButtonSkeleton />
        <TimerButtonSkeleton />
      </TimerButtonGrid>
    </RoomTemplate>
  );
};

const ErrorState: React.FC = () => {
  return (
    <RoomTemplate roomName="不明な部屋">
      <Text color="gray.500" fontSize="sm">
        部屋が見つかりませんでした。
      </Text>
    </RoomTemplate>
  );
};

const IdealState: React.FC = () => {
  const [user] = useAuthState(firebaseAuth);

  const roomId = useRoomSelector((v) => v.data.id);
  const ownerId = useRoomSelector((v) => v.data.ownerId);
  const timers = useRoomSelector((v) => v.data.timers);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const openShareModal = useCallback(() => setIsShareModalOpen(true), []);
  const closeShareModal = useCallback(() => setIsShareModalOpen(false), []);
  const openSettingsModal = useCallback(() => setIsSettingsModalOpen(true), []);
  const closeSettingsModal = useCallback(
    () => setIsSettingsModalOpen(false),
    []
  );

  const sortedTimerEntries = useMemo(
    () => Object.entries(timers).sort((a, b) => a[1].index - b[1].index),
    [timers]
  );

  // 10分ごとにサーバの時刻と同期する。
  useEffectOnce(() => void getServerStatus());
  useInterval(() => void getServerStatus(), 10 * 60 * 1000);

  useNoSleep();

  return (
    <RoomTemplate
      isOwner={user?.uid === ownerId}
      onSettingsClick={openSettingsModal}
      onShareClick={openShareModal}
      roomName={`部屋${roomId.substring(0, 6)}`}
    >
      <Box mb={6}>
        <StopAllButton />
      </Box>
      <TimerButtonGrid>
        {sortedTimerEntries.map(([timerId]) => (
          <TimerButton key={timerId} timerId={timerId} />
        ))}
      </TimerButtonGrid>

      <RoomShareModal isOpen={isShareModalOpen} onClose={closeShareModal} />
      <RoomSettingsModal
        isOpen={isSettingsModalOpen}
        onClose={closeSettingsModal}
      />
    </RoomTemplate>
  );
};

const _RoomPage: React.FC = () => {
  const { roomId } = useParams();
  const [roomSnapshot, isLoading, error] = useDocument(
    roomId ? roomRef(roomId) : undefined
  );
  const room = useMemo(() => roomSnapshot?.data(), [roomSnapshot]);

  if (isLoading) return <LoadingState />;
  if (!room || error) return <ErrorState />;
  return (
    <RoomProvider data={room}>
      <IdealState />
    </RoomProvider>
  );
};

export const RoomPage: React.FC = () => {
  const { roomId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const password = searchParams.get('p');

  const [error, setError] = useState<unknown>();

  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  useEffect(() => {
    void (async () => {
      if (!roomId || !password) return;

      try {
        await joinRoom({ password, roomId });
        setSearchParamsRef.current({});
      } catch (error) {
        setError(error);
      }
    })();
  }, [password, roomId]);

  if (password) return <LoadingState />;
  if (error) return <ErrorState />;
  return <_RoomPage />;
};
