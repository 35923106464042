type TimerColorId =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'cyan'
  | 'blue'
  | 'purple'
  | 'pink'
  | 'white'
  | 'gray'
  | 'black';

interface TimerColor {
  colorScheme: string;
  labelColor: string;
  name: string;
}

export const timerColors: Record<TimerColorId, TimerColor> = {
  red: {
    colorScheme: 'timerRed',
    labelColor: 'white',
    name: '赤',
  },
  orange: {
    colorScheme: 'timerOrange',
    labelColor: 'white',
    name: '橙',
  },
  yellow: {
    colorScheme: 'timerYellow',
    labelColor: 'black',
    name: '黄',
  },
  green: {
    colorScheme: 'timerGreen',
    labelColor: 'white',
    name: '緑',
  },
  teal: {
    colorScheme: 'timerTeal',
    labelColor: 'white',
    name: '青緑',
  },
  cyan: {
    colorScheme: 'timerCyan',
    labelColor: 'black',
    name: '水',
  },
  blue: {
    colorScheme: 'timerBlue',
    labelColor: 'white',
    name: '青',
  },
  purple: {
    colorScheme: 'timerPurple',
    labelColor: 'white',
    name: '紫',
  },
  pink: {
    colorScheme: 'timerPink',
    labelColor: 'white',
    name: '桃',
  },
  white: {
    colorScheme: 'timerWhite',
    labelColor: 'black',
    name: '白',
  },
  gray: {
    colorScheme: 'timerGray',
    labelColor: 'black',
    name: '灰',
  },
  black: {
    colorScheme: 'timerBlack',
    labelColor: 'white',
    name: '黒',
  },
} as const;
