import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  divider?: boolean;
  endActions?: React.ReactNode;
  heading?: React.ReactNode;
  startActions?: React.ReactNode;
  text?: React.ReactNode;
}

export const CardListItem: React.FC<Props> = ({
  divider,
  endActions,
  heading,
  startActions,
  text,
}) => {
  return (
    <HStack
      borderBottom={divider ? '1px' : undefined}
      borderColor="gray.200"
      p={4}
      spacing={4}
    >
      {startActions && <HStack spacing={2}>{startActions}</HStack>}
      <VStack alignItems="flex-start" flexGrow={1} flexShrink={1} spacing={1}>
        {heading && <Heading size="sm">{heading}</Heading>}
        {text && (
          <Text color="gray.500" fontSize="sm" fontWeight="normal">
            {text}
          </Text>
        )}
      </VStack>
      {endActions && <HStack spacing={2}>{endActions}</HStack>}
    </HStack>
  );
};
