import type {
  RoomDocumentData,
  WithIdAndRef,
} from '@board-game-timer/shared/src/firebase';
import React from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export interface ContextValue {
  data: WithIdAndRef<RoomDocumentData>;
}

const Context = createContext<ContextValue | undefined>(undefined);

export function useRoomSelector<Selected>(
  selector: (value: ContextValue) => Selected
): Selected {
  return useContextSelector(Context, (value) => {
    if (!value) throw new Error("'RoomProvider' is missing.");
    return selector(value);
  });
}

interface Props {
  children: React.ReactNode;
  data: WithIdAndRef<RoomDocumentData>;
}

export const RoomProvider: React.FC<Props> = ({ children, data }) => {
  return <Context.Provider value={{ data }}>{children}</Context.Provider>;
};
