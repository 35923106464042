import { ChakraProvider } from '@chakra-ui/react';
import createEmotion from '@emotion/css/create-instance';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import { IconContext } from 'react-icons';
import { theme } from '../../theme';

export const emotion = createEmotion({ key: 'css' });

const iconClassName = emotion.css({
  display: 'inline-block',
  fontSize: '1.5em',
  lineHeight: 1,
  verticalAlign: '-25%',
});

export const UiProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ChakraProvider theme={theme}>
      <CacheProvider value={emotion.cache}>
        <IconContext.Provider value={{ className: iconClassName }}>
          {children}
        </IconContext.Provider>
      </CacheProvider>
    </ChakraProvider>
  );
};
